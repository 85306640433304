.header{

    &__logo{
        width: 60px;
    }
    
    &__stage{
        background-color: transparent;
    }
}

@media (max-width: 767px) {

    .header{

        &__img{
            padding: 20px 0 0 10px;
        }

        &__btn{
            display: none;
        }
    }

    .menu{

        .burger__menu {

            position: fixed;
            right: 10px;
            top: 15px;
            width: 30px;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 1000;
            background-color: transparent;
            border-width: 0;
            padding: 0;

            &.designopen .bar:nth-child(2) {
                opacity: 0;
            }
            
            &.designopen .bar:nth-child(1) {
                transform: translateY(8px) rotate(45deg);
            }
            
            &.designopen .bar:nth-child(3) {
                transform: translateY(-9px) rotate(-45deg);
            }

            .bar {
                width: 100%;
                height: 4px;
                margin: 5px 0 0 0;
                background-color: var(--background-svg);
                transition: transform 0.3s ease;
            }
        }

        &__ul {

            position: fixed;
            background-color: var(--background-section);
            width: 100%;
            height: 100%;
            bottom: -100%; 
            list-style: none;
            padding: 0;
            text-align: center;
            transition: transform 0.5s ease-in-out, bottom 0.5s ease-in-out; 
            z-index: 999;

            & a {
                text-decoration: none;
                color: var(--color-white);
                text-align: center;
            }

            .menu__el {

                margin: 50px 0 0 0;
                transform: translateX(20px);
                opacity: 0;
                transition: all 0.5s ease-out;

                .menu--open & {
                    transform: translateY(0);
                    opacity: 1;
                }
                
                .menu--open &:nth-child(2) {
                    transition-delay: 0.2s;
                }
                
                .menu--open &:nth-child(3) {
                    transition-delay: 0.4s;
                }
                
                .menu--open &:nth-child(4) {
                    transition-delay: 0.6s;
                }
                
                &:hover a:hover {
                    color: var(--background-btn);
                }
                
                &--active a {
                    color: var(--background-btn);
                }
            }
        }

        &--open &__ul {
            transform: translateY(0);
            bottom: 0;
        }
    }
}

@media (min-width: 768px) {
    
    .header__sticky {

        position: sticky;
        top: 0;
        z-index: 1000;
    
        .header {

            display: flex;
            align-items: center; 
            padding: 16px 40px 10px 40px;

            &__logo{
                margin-top: 10px;
            }
    
            &__img {
                display: flex;
            }
    
            .menu {

                margin-left: auto;
            
                &__ul {
                    display: flex;
                    gap: 40px;
                    padding: 0 0 2px 0;
                }
            
                &__el {

                    list-style: none;
                    align-items: center;
                    position: relative;
                    overflow: hidden;
            
                    &.menu__el--active a {

                        color: var(--background-btn);
                        
                        &::after {
                            transform: scaleX(1);
                            transform-origin: bottom left;
                        }
                    }
                }
            
                &__el a {

                    color: var(--title);
                    text-decoration: none;
                    position: relative;
                    display: inline-block;
                    padding: 5px 0;
                    transition: color 0.3s;
            
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        transform: scaleX(0);
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        background-color: var(--background-btn);
                        transform-origin: bottom right;
                        transition: transform 0.25s ease-out;
                    }
                }
            
                &__el:hover a,
                &__el:focus a {
                    color: var(--background-btn);
                }
            
                &__el:hover a::after,
                &__el:focus a::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            
                &__el:hover {

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 300%;
                        height: 300%;
                        transform: translateX(-50%) translateY(-50%) scale(0);
                        transition: transform 0.5s ease-out;
                        border-radius: 50%;
                        z-index: -1;
                    }
                }
            
                &__el:focus {
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 300%;
                        height: 300%;
                        transform: translateX(-50%) translateY(-50%) scale(0);
                        transition: transform 0.5s ease-out;
                        border-radius: 50%;
                        z-index: -1;
                    }
                }
            
                &__el:hover::before,
                &__el:focus::before {
                    transform: translateX(-50%) translateY(-50%) scale(1);
                }
            }
            
            .btn {
                
                font-size: var(--font-par);
                font-family: "Lexend", sans-serif;
                font-optical-sizing: auto;
                font-style: normal;
                font-weight: 300;
                position: relative;
                margin-top: 2px;
                overflow: hidden;
                display: inline-block;
                text-decoration: none;
                text-align: center;
                cursor: pointer;
            
            
                &--talk {
                    background-color: var(--background-btn);
                    border: 1px solid var(--title);
                    padding: 7px 12px 6px 12px;
                    color: var(--title-white);
                    margin-left: 40px; 
                    transition: background-color 0.3s ease, color 0.3s ease;

                    &:hover,
                    &:focus {
                        background-color: var(--title-white);
                        color: var(--background-btn);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        width: 0;
                        height: 2px;
                        background-color: var(--background-btn);
                        transition: width 0.3s ease, left 0.3s ease;
                    }

                    &:hover::after,
                    &:focus::after {
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
    }
}
