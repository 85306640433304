// WAVES ///////////////////////////////////////////////////////////////////////////

.waves {

  position:relative;
  width: 100%;
  height: 15vh;
  margin-bottom:-10px; 
  background-color: var(--title-white);
  min-height:100px;
  max-height:150px;
  transition: all 0.1s cubic-bezier(.4,.13,.58,.84);

  &__color{

    fill: var(--background-section);

    &--1{
       opacity: 0.7;
    }
    &--2{
      opacity: 0.5;
    }
    &--3{
      opacity: 0.3;
    }
    &--4{
      opacity: 1;
    }
  }

  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }

  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }
}

.footer__el{

  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--background-section);

  .dwt{

    width: 50px;
    transform: translateY(3px);

    &__txt {
      fill: var(--title);
      transition: fill 1s;
    }
    
    &__und {
      fill: var(--background-btn);
    }
  }

  .credits {

    color: var(--title);
    padding: 10px 0 0 0;

    & a {
      text-decoration: none;
    }

    .display-cred {

      color: var(--title);

      &:hover {
        color: var(--background-btn);
        text-decoration: underline;
      }
    }
  }

  .subNav {
    list-style: none;
    text-transform: uppercase;
    align-items: center;
    padding: 30px 0 20px 0;
    margin: 0;

    &__el a {
      text-decoration: none;
      color: var(--title);
    }
    
    &__el--active a {
      color: var(--background-btn);
      text-decoration: underline; 
    } 
    
    &__el a:hover {
      color: var(--background-btn);
      text-decoration: underline;
    }
  }
}

  