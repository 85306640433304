.section__small{
    
    margin: 60px 0;

    .credit{

        margin: 100px 40px;

        img{
            margin-right: 10px;
            width: 20px;
        }

        &__flex{
            padding: 20px;

        }

        &__ul{
            padding: 40px;
        }

        &__el{
            list-style: none;   
            padding-bottom: 20px; 

            a{
                color: var(--title);
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 767px) {

    .section__small{

        .credit{
            margin: 60px 20px;
        }
    }
}

@media (min-width: 768px) {

    .section__small{

        .credit{
            margin: 100px 100px;
        }
    }
}