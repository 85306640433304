.projets{
    
    border-radius: 10px;

   &__swiper{

        margin: 0 40px 0 40px;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    
        .imgProjets{
            height: 60%;
            width: 100%; 
            position: absolute;
            background-image: url("../assets/images/TFA.png");
            background-size: cover;
            background-position: center;
            border-radius: 30px;
            opacity: 0.3;
            z-index: 10;
        }

        .para__projets{
            color: var(--title);
            line-height: 1.5;
            padding: 0 40px 0 40px;
        }

        .medium{   
            text-align: center;
            padding: 0;
            margin: 0;

            &__li{
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                list-style: none;
                font-weight: 600;
                padding: 20px 0 20px 0;

                transform: translate(-50%, -50%);
                transition: .3s;

                a{
                    text-decoration: none;
                }

                h3{
                    text-transform: uppercase;
                }

                &.active{
                    line-height: 80px;
                }

                &:has(~ .active) { // Toutes options avant
                    transform: translate(-50%, -130px);
                    opacity: 0;

                }
                &:has(+ .active) { // li qui précède directement
                    transform: translate(-50%, -80px);
                    opacity: 0;
                }
                &.active ~ .medium__li { // Toutes options après
                    transform: translate(-50%, 100px);
                    opacity: 0; 
                }
                &.active + .medium__li { // li qui suit directement
                    transform: translate(-50%, 50px);    
                    opacity: 0;
                }
            }
        }
    }
}

@media (min-width: 768px) {

    .projets{
    
       &__swiper{
        
            .imgProjets{
                height: 75%;
            }
    
            .medium{   
    
                &__li{
                    transition: .5s;
    
                    &:has(~ .active) { // Toutes options avant
                        transform: translate(-50%, -170px);
    
                    }
                    &:has(+ .active) { // li qui précède directement
                        transform: translate(-50%, -140px);
                    }
                    &.active ~ li { // Toutes options après
                        transform: translate(-50%, 130px);
                    }
                    &.active + li { // li qui suit directement
                        transform: translate(-50%, 100px);    
                    }
                }
            }
        }
    }
}



