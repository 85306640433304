@import "components/_projets";
@import "components/_stage";
@import "components/_footer";
@import "components/_header";
@import "components/_credits";
@import "components/_ui-kit";

:root{
    --title: #203030;
    --title-white: #fff;
    --background-skills: #CCE3DE;
    --background-section: #A4C3B2;
    --background-btn: #AB463F;
    --background-svg: #203030;
    
    --font-big: 61px;
    --font-med: 41px;
    --font-light: 27px;
    --font-par: 18px;
}

*{
    box-sizing: border-box;
    margin: 0;
}

body{
    margin: auto 0;
    font-size: var(--font-par);
    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
}

.title{

    font-family: "Unbounded", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: var(--title);
  
    &--big{
      font-size: var(--font-big);
      text-transform: uppercase;
      color: var(--title-white);

      span{
        color: var(--background-svg);
      }
    }
  
    &--med{
      font-size: var(--font-med);

      &__align{
        text-align: center;
      }

    }

    &--light{
        font-size: var(--font-light);
    }
}

@media (max-width: 767px) {

    .title{
      
        &--big{
    
          &__hide{
            display: none;
          }

          span{
            color: var(--background-btn);
          }

        }
    }
}

@media (min-width: 768px) {

    .title{
      
        &--big{
            opacity: 0;
            transform: translateY(50px) scale(0.8) rotate(-10deg);
            animation: complexAnimation 1.5s ease forwards;

            &:nth-child(1) {
                animation-delay: 0s;
            }
              
            &:nth-child(2) {
                animation-delay: 0.5s;
            }
              
            &:nth-child(3) {
                animation-delay: 1s;
            }
    
            &__hide{
              padding: 0 0 40px 0;
            }

        }
    }

    .pad__title{
        padding: 100px 0 0 0;
    }
      
    @keyframes complexAnimation {
        0% {
          opacity: 0;
          transform: translateY(-50px) scale(0.8);
        }
        50% {
          opacity: 0.5;
          transform: translateY(-10px) scale(1.1);
        }
        100% {
          opacity: 1;
          transform: translateY(0) scale(1);
        }
    }
}

// SECTION WIDTH //////////////////////////////////////////////////////////////////

.section__width{
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    
    .section__width {
        max-width: 900px;
    }
      
}

@media (min-width: 1200px) {
    
    .section__width{
        max-width: 1200px;
    }   
}

// SECTION ABOUT ME //////////////////////////////////////////////////////////////////

.aboutme{

    position: relative;
    background-image: url(../assets/images/wave-section.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: var(--title-white);
    bottom: 0;
    left: 0;
    padding: 0 0 100px 0;

    .parag{
        padding: 40px 0 0 20px;
    }

    p{
        max-width: 600px;
        padding: 0 0 20px 0;
    }

    .pad__title{
        padding: 60px 0 60px 10px;
    }

}

@media (max-width: 767px) {

    .aboutme{

        &__grid{
            padding: 60px 0 60px 10px;
        }
    
        &__flex{
            display: flex;
            justify-content: center;

            &__avatar{
                width: 320px;
            }
        }
    }
}

@media (min-width: 768px) {

    .aboutme{

        &__back{
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-template-rows: auto;
            padding: 100px 40px 100px 40px;
        }

        &__flex{
            grid-column: 7;
            grid-row: 1;

            &__avatar{
                width: 300px;
            }
        }

        &__grid{
            grid-column: 1 / 6;
        }

        .pad__title{
            text-align: center;
        }
    }
}

// SECTION PROJETS //////////////////////////////////////////////////////////////////

.section{
    background-color: var(--title-white);
}

.body__back{
    background-color: var(--background-section);
}

.section__ae{
    padding: 100px 0;
}

.projets__container{

    position: relative;

    .cercle__svg{
        position: absolute;
        top: 40%;
        left: 49%; 
        width: 280px;
        transform: translate(-50%, -50%);
        z-index: 1;
        fill: none;
        stroke: var(--background-btn);
        stroke-width: 2px;

        --length: 686.6195068359375px;
        stroke-dasharray: var(--length);
        stroke-dashoffset: var(--length);
        transition: all 0.5s;

    }

}

@media (min-width: 1200px) {

    .projets__container{
    
        .cercle__svg{
            position: absolute;
            width: 420px;
            top: 40%;
            left: 49%; 
            transform: translate(-50%, -50%);
            z-index: 1;
        }
    
    }
}

// SECTION SKILLS //////////////////////////////////////////////////////////////////

.section__skills{

    overflow: hidden;
    padding-top: 40px;

    .skills__para{
        padding: 60px 20px 100px 20px;
    }

    .title__pad{
        padding: 40px 0;
    }
}

.logos {

    white-space: nowrap;
    margin: 0 -130px;
    background-color: var(--background-skills);

    .scroller {

        display: inline-block;
        -webkit-animation: 45s linear infinite auto-scroll;
        animation: 45s linear infinite auto-scroll;
        padding: 20px 0 20px 0;
    

        &--center{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__el {
            display: inline-block;
            margin-left: 128px;
            max-width: 400px;
            max-height: 200px;
            text-transform: uppercase;
        }
    }
}
  
@-webkit-keyframes auto-scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
}
  
@keyframes auto-scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
}

@media (max-width: 767px) {

    .skills__para{

        p{
            padding-left: 40px;
        }
    }

}

@media (min-width: 768px) {

    .skills__para{

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 40px;

        .grid__1{
            grid-column: 1;
            grid-row: 1;
            justify-self: center;
        }
        
        .grid__2{
            grid-column: 2;
            grid-row: 1;
            justify-self: center;
        }
        
        .grid__3{
            grid-column: 1;
            grid-row: 2;
            justify-self: center;
        }
        
        .grid__4{
            grid-column: 2;
            grid-row: 2;
            justify-self: center;
        }

        .para{
            padding-left: 40px;
            max-width: 500px;
        }
    }
}

@media (min-width: 768px) {

.section__skills{

    .skills__para{
        padding: 100px 20px 100px 20px;
    }
}
}

// SECTION AFTER EFFECTS //////////////////////////////////////////////////////////////////

.section__ae{

    .swiper {
        width: 100%;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 300px;
        height: 400px;
        margin: 0 30px 0 30px;

        .test-cover{
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: transform 0.3s ease;
        }
    
        iframe{
            width: 100%;
            height: 100%;
            object-fit: cover;    
        }
    }
}

.grid-test{
    padding: 60px 20px;

    .para{
        grid-column: 4 / 8;
    }
}

.modal{

    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
    animation: scaleIn 0.6s cubic-bezier(0, 0.87, 0.58, 1);

    &__content {
        position: relative;
        background: none;
        width: 300px; 
        height: 350px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .close {
        color: white;
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 35px;
        font-weight: bold;
        cursor: pointer;
      }
      
    .close:hover,
    .close:focus {
        color: var(--background-btn);
        text-decoration: none;
        cursor: pointer;
    }
    
    &__video{
        border: none;
    }
}
  
@keyframes scaleIn {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
  
@media (min-width: 768px) {

    .section__ae{
    
        .swiper-slide {
            margin: 50px 0;
    
        }
    }

    .grid-test{
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: auto;
        padding: 60px 0 20px 0;
    
        .para{
            grid-column: 5 / 10;
            padding-right: 20px;
            max-width: 700px;
        }
    }
}

@media (min-width: 1000px) {

    :root{ 
        --font-big: 81px;
    }
}
 
@media (min-width: 1200px) {

    :root{ 
        --font-big: 101px;
        --font-med: 68px;
        --font-light: 45px;
        --font-par: 20px;
    }

    .grid-test{
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-template-rows: auto;
        padding: 100px 0 60px 0;

        .para{
            grid-column: 7 / 14;
        }
    }

    .aboutme{

        &__back{
            padding: 200px 60px;
        }

        &__flex__avatar{
            width: 400px;
        }
    }

    .section__ae {
        padding: 100px 0;
    }

    .section__ae{
    
        .swiper-slide {
            width: 350px;
            height: 480px;
    
        }
    }
}







  



